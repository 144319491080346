import React from 'react'
import SliderServices from '../../component/slidercomponent/SliderServices'

const HomeServices = () => {
  return (
    <>
      <div className="case-study py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto mt-lg-5">
              <div className="site-heading text-center wow fadeInDown " data-wow-delay=".25s">
                <span className="site-title-tagline"><i className="fas fa-percent" /> Our Services</span>
                <h2 className="site-title" style={{color:"#33b6ff"}}>Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row popup-gallery wow fadeInUp" data-wow-delay=".25s">
            <div className="case-slider owl-theme">
              <SliderServices />
              {/* <div className="case-item">
                <div className="case-img">
                  <a className="popup-img case-link" to="assets/img/case/01.jpg"> <i className="fal fa-plus" /></a>
                  <img className="img-fluid" src="assets/img/case/01.jpg" alt />
                  <div className="case-btn">
                    <a to="case-study-single.html"><i className="far fa-arrow-right" /></a>
                  </div>
                </div>
                <div className="case-content">
                  <small>Tax Advice</small>
                  <h4><a to="case-study-single.html">Corporate Tax Planning</a></h4>
                </div>
              </div>
              <div className="case-item">
                <div className="case-img">
                  <a className="popup-img case-link" to="assets/img/case/02.jpg"> <i className="fal fa-plus" /></a>
                  <img className="img-fluid" src="assets/img/case/02.jpg" alt />
                  <div className="case-btn">
                    <a to="case-study-single.html"><i className="far fa-arrow-right" /></a>
                  </div>
                </div>
                <div className="case-content">
                  <small>Tax Planning</small>
                  <h4><a to="case-study-single.html">Corporate Tax Planning</a></h4>
                </div>
              </div>
              <div className="case-item">
                <div className="case-img">
                  <a className="popup-img case-link" to="assets/img/case/03.jpg"> <i className="fal fa-plus" /></a>
                  <img className="img-fluid" src="assets/img/case/03.jpg" alt />
                  <div className="case-btn">
                    <a to="case-study-single.html"><i className="far fa-arrow-right" /></a>
                  </div>
                </div>
                <div className="case-content">
                  <small>Personal Tax</small>
                  <h4><a to="case-study-single.html">Corporate Tax Planning</a></h4>
                </div>
              </div>
              <div className="case-item">
                <div className="case-img">
                  <a className="popup-img case-link" to="assets/img/case/04.jpg"> <i className="fal fa-plus" /></a>
                  <img className="img-fluid" src="assets/img/case/04.jpg" alt />
                  <div className="case-btn">
                    <a to="case-study-single.html"><i className="far fa-arrow-right" /></a>
                  </div>
                </div>
                <div className="case-content">
                  <small>Insurance Tax</small>
                  <h4><a to="case-study-single.html">Corporate Tax Planning</a></h4>
                </div>
              </div>
              <div className="case-item">
                <div className="case-img">
                  <a className="popup-img case-link" to="assets/img/case/05.jpg"> <i className="fal fa-plus" /></a>
                  <img className="img-fluid" src="assets/img/case/05.jpg" alt />
                  <div className="case-btn">
                    <a to="case-study-single.html"><i className="far fa-arrow-right" /></a>
                  </div>
                </div>
                <div className="case-content">
                  <small>Corporate Tax</small>
                  <h4><a to="case-study-single.html">Corporate Tax Planning</a></h4>
                </div>
              </div>
              <div className="case-item">
                <div className="case-img">
                  <a className="popup-img case-link" to="assets/img/case/06.jpg"> <i className="fal fa-plus" /></a>
                  <img className="img-fluid" src="assets/img/case/06.jpg" alt />
                  <div className="case-btn">
                    <a to="case-study-single.html"><i className="far fa-arrow-right" /></a>
                  </div>
                </div>
                <div className="case-content">
                  <small>Tax Audit</small>
                  <h4><a to="case-study-single.html">Corporate Tax Planning</a></h4>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>


    </>
  )
}

export default HomeServices