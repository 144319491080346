import React from 'react'
import SliderTestimonials from '../../component/slidercomponent/SliderTestimonials'

const Testimonials = () => {
    return (
        <>

            <div className="testimonial-area bg py-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="site-heading wow fadeInDown" data-wow-delay=".25s">
                                <span className="site-title-tagline light"><i className="fas fa-percent" /> Testimonials</span>
                                <h2 className="site-title" style={{color:"#33b6ff"}}>What Our Client <span>Say's</span></h2>
                                <p>
                                Hear from our satisfied clients about their experiences with our recruitment services.
                                </p>
                                {/* <a to="contact.html" className="theme-btn mt-30">Know More <i className="fas fa-arrow-right" /></a> */}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="testimonial-slider  owl-theme wow fadeInUp" data-wow-delay=".25s">
                                <SliderTestimonials />
                                {/* <div className="testimonial-item">
                                    <div className="testimonial-quote">
                                        <span className="testimonial-quote-icon"><i className="fal fa-quote-right" /></span>
                                        <p>
                                            There are many variations of passages of Lorem Ipsum available, but the majority
                                            have
                                            suffered alteration in some form, by injected humour.
                                        </p>
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="testimonial-author-img">
                                            <img src="assets/img/testimonial/01.jpg" alt />
                                        </div>
                                        <div className="testimonial-author-info">
                                            <h4>Niesha Phips</h4>
                                            <p>Customer</p>
                                            <div className="testimonial-rate">
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-quote">
                                        <span className="testimonial-quote-icon"><i className="fal fa-quote-right" /></span>
                                        <p>
                                            There are many variations of passages of Lorem Ipsum available, but the majority
                                            have
                                            suffered alteration in some form, by injected humour.
                                        </p>
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="testimonial-author-img">
                                            <img src="assets/img/testimonial/02.jpg" alt />
                                        </div>
                                        <div className="testimonial-author-info">
                                            <h4>Daniel Porter</h4>
                                            <p>Customer</p>
                                            <div className="testimonial-rate">
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-quote">
                                        <span className="testimonial-quote-icon"><i className="fal fa-quote-right" /></span>
                                        <p>
                                            There are many variations of passages of Lorem Ipsum available, but the majority
                                            have
                                            suffered alteration in some form, by injected humour.
                                        </p>
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="testimonial-author-img">
                                            <img src="assets/img/testimonial/03.jpg" alt />
                                        </div>
                                        <div className="testimonial-author-info">
                                            <h4>Ebony Swihart</h4>
                                            <p>Customer</p>
                                            <div className="testimonial-rate">
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-quote">
                                        <span className="testimonial-quote-icon"><i className="fal fa-quote-right" /></span>
                                        <p>
                                            There are many variations of passages of Lorem Ipsum available, but the majority
                                            have
                                            suffered alteration in some form, by injected humour.
                                        </p>
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="testimonial-author-img">
                                            <img src="assets/img/testimonial/04.jpg" alt />
                                        </div>
                                        <div className="testimonial-author-info">
                                            <h4>Loreta Jones</h4>
                                            <p>Customer</p>
                                            <div className="testimonial-rate">
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                                <i className="fas fa-star" />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Testimonials