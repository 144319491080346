import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";


// Import Swiper styles
import 'swiper/css';


import { Autoplay } from 'swiper/modules';

export default function HomeBannerSlider() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}


                modules={[Autoplay]}
                className="mySwiper"
            >


                <SwiperSlide>
                    <div className="hero-single" style={{ background: 'url(assets/img/hero/slider-2.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-lg-7">
                                    <div className="hero-content" >
                                        <h6 className="hero-sub-title" data-animation="fadeInUp" data-delay=".25s" style={{color:"white"}}>Welcome To
                                            Bright Jobs India </h6>
                                        <h1 className="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                            Turnkey Recruitment Assignments
                                        </h1>
                                        <p data-animation="fadeInLeft" data-delay=".75s">
                                            Specialized recruitment process that handles large volume requirements; 
                                        </p>
                                        <div className="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                            <Link to="/about" className="theme-btn">About More<i className="fas fa-arrow-right" /></Link>
                                            <Link to="/contact" className="theme-btn theme-btn2">Learn More<i className="fas fa-arrow-right" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="hero-single" style={{ background: 'url(assets/img/hero/slider-1.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-lg-7">
                                    <div className="hero-content">
                                        <h6 className="hero-sub-title" data-animation="fadeInUp" data-delay=".25s" style={{color:"white"}}>Welcome To
                                            Bright Jobs India </h6>
                                        <h1 className="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                            Permanent Staffing
                                        </h1>
                                        <p data-animation="fadeInLeft" data-delay=".75s">
                                            Caters to requirements for Junior, Middle and Management Level positions.
                                        </p>
                                        <div className="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                            <Link to="/about" className="theme-btn">About More<i className="fas fa-arrow-right" /></Link>
                                            <Link to="/contact" className="theme-btn theme-btn2">Learn More<i className="fas fa-arrow-right" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

                {/* <SwiperSlide>
                    <div className="hero-single" style={{ background: 'url(assets/img/hero/slider-3.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-lg-7">
                                    <div className="hero-content">
                                        <h6 className="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To
                                            Bright Jobs India </h6>
                                        <h1 className="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                            Maximize your tax savings with us
                                        </h1>
                                        <p data-animation="fadeInLeft" data-delay=".75s">
                                            There are many variations of passages orem psum available but the majority have
                                            suffered alteration in some form by injected humour.
                                        </p>
                                        <div className="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                            <a to="about.html" className="theme-btn">About More<i className="fas fa-arrow-right" /></a>
                                            <a to="contact.html" className="theme-btn theme-btn2">Learn More<i className="fas fa-arrow-right" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide> */}

            </Swiper>
        </>
    );
}
