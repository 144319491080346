import React from 'react'
import { Link } from "react-router-dom";


const Blog = () => {
    return (
        <>
            <div>
                <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/01.jpg)' }}>
                    <div className="container">
                        <h2 className="breadcrumb-title">Our Blog</h2>
                        <ul className="breadcrumb-menu">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">Our Blog</li>
                        </ul>
                    </div>
                </div>
                <div className="blog-area py-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                                <div className="site-heading text-center wow fadeInDown" data-wow-delay=".25s">
                                    <span className="site-title-tagline"><i className="fas fa-percent" /> Our Blog</span>
                                    <h2 className="site-title" style={{color:"#33b6ff"}}>Our Latest News &amp; <span>Blog</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row g-4">
                            <div className="col-md-6 col-lg-4">
                                <div className="blog-item wow fadeInUp" data-wow-delay=".25s">
                                    <div className="blog-item-img">
                                        <img src="assets/img/blog/01.jpg" alt="Thumb" />
                                        <div className="blog-date">
                                            <strong>20</strong>
                                            <span>May</span>
                                        </div>
                                    </div>
                                    <div className="blog-item-info">
                                        {/* <div className="blog-item-meta">
                                            <ul>
                                                <li><a to="#"><i className="far fa-user-circle" /> By Alicia Davis</a></li>
                                                <li><a to="#"><i className="far fa-comments" /> 2.5k Comments</a></li>
                                            </ul>
                                        </div> */}
                                        <h4 className="blog-title">
                                            <a to="blog-single.html">The Future of Recruitment: Trends to Watch.</a>
                                        </h4>
                                        <p>
                                        Explore the emerging trends that are shaping the future of recruitment in various industries.
                                        </p>
                                        {/* <a className="theme-btn" to="blog-single.html">Read More<i className="fas fa-arrow-right" /></a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="blog-item wow fadeInDown" data-wow-delay=".25s">
                                    <div className="blog-item-img">
                                        <img src="assets/img/blog/02.jpg" alt="Thumb" />
                                        <div className="blog-date">
                                            <strong>25</strong>
                                            <span>Jun</span>
                                        </div>
                                    </div>
                                    <div className="blog-item-info">
                                        {/* <div className="blog-item-meta">
                                            <ul>
                                                <li><a to="#"><i className="far fa-user-circle" /> By Alicia Davis</a></li>
                                                <li><a to="#"><i className="far fa-comments" /> 1.2k Comments</a></li>
                                            </ul>
                                        </div> */}
                                        <h4 className="blog-title">
                                            <a to="blog-single.html">How to Write an Effective Job Description.</a>
                                        </h4>
                                        <p>
                                        Learn the key elements of crafting job descriptions that attract the right candidates.
                                        </p>
                                        {/* <a className="theme-btn" to="blog-single.html">Read More<i className="fas fa-arrow-right" /></a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="blog-item wow fadeInUp" data-wow-delay=".25s">
                                    <div className="blog-item-img">
                                        <img src="assets/img/blog/03.jpg" alt="Thumb" />
                                        <div className="blog-date">
                                            <strong>28</strong>
                                            <span>Sept</span>
                                        </div>
                                    </div>
                                    <div className="blog-item-info">
                                        {/* <div className="blog-item-meta">
                                            <ul>
                                                <li><a to="#"><i className="far fa-user-circle" /> By Alicia Davis</a></li>
                                                <li><a to="#"><i className="far fa-comments" /> 2.8k Comments</a></li>
                                            </ul>
                                        </div> */}
                                        <h4 className="blog-title">
                                            <a to="blog-single.html">The Importance of Employer Branding.</a>
                                        </h4>
                                        <p>
                                        Discover why employer branding is essential for attracting top talent in today’s job market
                                        </p>
                                        {/* <a className="theme-btn" to="blog-single.html">Read More<i className="fas fa-arrow-right" /></a> */}
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        
                    </div>
                </div>
            </div>


        </>
    )
}

export default Blog