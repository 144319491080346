import React from 'react'
import SliderClient from '../../component/slidercomponent/SliderClient'

const Client = () => {
    return (
        <>
            <div>
                <div className="blog-area py-100 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto mt-lg-3">
                                <div className="site-heading text-center wow fadeInDown" data-wow-delay=".25s">
                                    {/* <span className="site-title-tagline"><i className="fas fa-percent " /> Our Blog</span> */}
                                    <h2 className="site-title" style={{color:"#33b6ff"}}>Our Client</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="partner-area bg pt-60 pb-60">
                    <div className="container">
                        <div className="partner-wrapper partner-slider  owl-theme">
                            <SliderClient />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Client