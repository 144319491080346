import React from 'react'
import { Link } from "react-router-dom";
import HomeBannerSlider from '../../component/slidercomponent/HomeBannerSlider';


const HomeBanner = () => {
    return (
        <>


            {/* <div className="sidebar-popup">
                <div className="sidebar-wrapper">
                    <div className="sidebar-content">
                        <button type="button" className="close-sidebar-popup"><i className="far fa-xmark" /></button>
                        <div className="sidebar-logo">
                            <img src="assets/img/logo/logo.png" alt />
                        </div>
                        <div className="sidebar-about">
                            <h4>About Us</h4>
                            <p>There are many variations of passages available sure there majority have suffered alteration in
                                some form by inject humour or randomised words which don't look even slightly believable.</p>
                        </div>
                        <div className="sidebar-contact">
                            <h4>Contact Info</h4>
                            <ul>
                                <li>
                                    <h6>Email</h6>
                                    <a href="/cdn-cgi/l/email-protection#deb7b0b8b19ebba6bfb3aeb2bbf0bdb1b3"><i className="far fa-envelope" /><span className="__cf_email__" data-cfemail="9bf2f5fdf4dbfee3faf6ebf7feb5f8f4f6">[email&nbsp;protected]</span></a>
                                </li>
                                <li>
                                    <h6>Phone</h6>
                                    <a href="tel:+21236547898"><i className="far fa-phone" />+2 123 654 7898</a>
                                </li>
                                <li>
                                    <h6>Address</h6>
                                    <a href="#"><i className="far fa-location-dot" />25/B Milford Road, New York</a>
                                </li>
                            </ul>
                        </div>
                        <div className="sidebar-social">
                            <h4>Follow Us</h4>
                            <a href="#"><i className="fab fa-facebook" /></a>
                            <a href="#"><i className="fab fa-x-twitter" /></a>
                            <a href="#"><i className="fab fa-instagram" /></a>
                            <a href="#"><i className="fab fa-linkedin" /></a>
                        </div>
                    </div>
                </div>
            </div> */}


            <div className="hero-section hs-2">
                {/* <div className="hero-text-shape">
                    <p>taxrio</p>
                </div> */}
                <div className="hero-slider owl-theme">
                {/* <div className="hero-slider owl-carousel owl-theme"> */}
                    <HomeBannerSlider />

                    {/* <div className="hero-single" style={{ background: 'url(assets/img/hero/slider-1.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-lg-7">
                                    <div className="hero-content">
                                        <h6 className="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To
                                            Taxrio!</h6>
                                        <h1 className="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                            Maximize your tax savings with us
                                        </h1>
                                        <p data-animation="fadeInLeft" data-delay=".75s">
                                            There are many variations of passages orem psum available but the majority have
                                            suffered alteration in some form by injected humour.
                                        </p>
                                        <div className="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                            <a href="about.html" className="theme-btn">About More<i className="fas fa-arrow-right" /></a>
                                            <a href="contact.html" className="theme-btn theme-btn2">Learn More<i className="fas fa-arrow-right" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-single" style={{ background: 'url(assets/img/hero/slider-2.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-lg-7">
                                    <div className="hero-content">
                                        <h6 className="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To
                                            Taxrio!</h6>
                                        <h1 className="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                            Maximize your tax savings with us
                                        </h1>
                                        <p data-animation="fadeInLeft" data-delay=".75s">
                                            There are many variations of passages orem psum available but the majority have
                                            suffered alteration in some form by injected humour.
                                        </p>
                                        <div className="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                            <a href="about.html" className="theme-btn">About More<i className="fas fa-arrow-right" /></a>
                                            <a href="contact.html" className="theme-btn theme-btn2">Learn More<i className="fas fa-arrow-right" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-single" style={{ background: 'url(assets/img/hero/slider-3.jpg)' }}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-lg-7">
                                    <div className="hero-content">
                                        <h6 className="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To
                                            Taxrio!</h6>
                                        <h1 className="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                            Maximize your tax savings with us
                                        </h1>
                                        <p data-animation="fadeInLeft" data-delay=".75s">
                                            There are many variations of passages orem psum available but the majority have
                                            suffered alteration in some form by injected humour.
                                        </p>
                                        <div className="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                            <a href="about.html" className="theme-btn">About More<i className="fas fa-arrow-right" /></a>
                                            <a href="contact.html" className="theme-btn theme-btn2">Learn More<i className="fas fa-arrow-right" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        </>
    )
}

export default HomeBanner