import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderClient() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
               
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 6,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 6,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                <img src="assets/img/partner/c1.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c2.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c3.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c4.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c5.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c6.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c7.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c8.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c9.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c10.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c11.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c12.jpg" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c13.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c14.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c15.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c16.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c17.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c18.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c19.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c20.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c21.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c22.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c23.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c34.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c25.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c26.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c27.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c28.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c29.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c30.png" alt="thumb" />
                </SwiperSlide>
                <SwiperSlide>
                <img src="assets/img/partner/c31.png" alt="thumb" />
                </SwiperSlide>
              
              


            </Swiper>
        </>
    );
}
