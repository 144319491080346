import React from 'react'
import { Link } from "react-router-dom";


const Contact = () => {
    return (
        <>

            <div>
                <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/01.jpg)' }}>
                    <div className="container">
                        <h2 className="breadcrumb-title">Contact Us</h2>
                        <ul className="breadcrumb-menu">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">Contact Us</li>
                        </ul>
                    </div>
                </div>
                <div className="contact-area py-120">
                    <div className="container">
                        <div className="contact-content">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="contact-info">
                                        <div className="contact-info-icon">
                                            <i className="fal fa-map-location-dot" />
                                        </div>
                                        <div className="contact-info-content">
                                            <h5>Office Address</h5>
                                            <p>2nd Floor, NTC Building. M.G. Road, Chikmagalur-577101</p>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="contact-info">
                                        <div className="contact-info-icon">
                                            <i className="fal fa-phone-volume" />
                                        </div>
                                        <div className="contact-info-content">
                                            <h5>Call Us</h5>
                                            <a href="tel:+916366288356">+91 6366288356 <br/>+91 9739095534</a>
                                            
                                            <br/>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="contact-info">
                                        <div className="contact-info-icon">
                                            <i className="fal fa-envelopes" />
                                        </div>
                                        <div className="contact-info-content">
                                            <h5>Email Us</h5>
                                            <p><a href="mailto:contact@brightjobsindia.com" className="__cf_email__" data-cfemail="4f262129200f2a372e223f232a612c2022">contact@brightjobsindia.com</a>
                                            </p>
                                            <br/>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="contact-info">
                                        <div className="contact-info-icon">
                                            <i className="fal fa-alarm-clock" />
                                        </div>
                                        <div className="contact-info-content">
                                            <h5>Open Time</h5>
                                            <p>Monday to Friday (9.30 Am - 6.30PM)</p>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="contact-wrapper">
                            <div className="row g-4">
                                <div className="col-lg-5">
                                    <div className="contact-img">
                                        <img src="assets/img/contact/01.jpg" alt />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="contact-form">
                                        <div className="contact-form-header">
                                            <h2>Get In Touch</h2>
                                            <p>It is a long established fact that a reader will be distracted by the readable
                                                content of a page randomised words which don't look even slightly when looking
                                                at its layout. </p>
                                        </div>
                                        <form method="post" action="/taxrio/assets/php/contact.php" id="contact-form">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="far fa-user-tie" /></span>
                                                        <input type="text" className="form-control" name="name" placeholder="Your Name" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="far fa-envelope" /></span>
                                                        <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group">
                                                <span className="input-group-text"><i className="far fa-pen" /></span>
                                                <input type="text" className="form-control" name="subject" placeholder="Your Subject" required />
                                            </div>
                                            <div className="input-group textarea">
                                                <span className="input-group-text"><i className="far fa-comment-lines" /></span>
                                                <textarea name="message" cols={30} rows={5} className="form-control" placeholder="Write Your Message" defaultValue={""} />
                                            </div>
                                            <button type="submit" className="theme-btn">Send
                                                Message <i className="far fa-paper-plane" /></button>
                                            <div className="col-md-12 mt-3">
                                                <div className="form-messege text-success" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <div className="contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96708.34194156103!2d-74.03927096447748!3d40.759040329405195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4a01c8df6fb3cb8!2sSolomon%20R.%20Guggenheim%20Museum!5e0!3m2!1sen!2sbd!4v1619410634508!5m2!1sen!2s" style={{ border: 0 }} allowFullScreen loading="lazy" />
                </div> */}
            </div>

        </>
    )
}

export default Contact