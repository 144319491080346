import React from 'react'
import { Link } from "react-router-dom";
import Client from '../home/Client';
import Testimonials from '../home/Testimonials';

const About = () => {
    return (
        <>

            <div>
                <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/01.jpg)' }}>
                    <div className="container">
                        <h2 className="breadcrumb-title">About Us</h2>
                        <ul className="breadcrumb-menu">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">About Us</li>
                        </ul>
                    </div>
                </div>
                <div className="about-area py-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-left wow fadeInLeft" data-wow-delay=".25s">
                                    <div className="about-img">
                                        <img className="img-1" src="assets/img/about/01.jpg" alt />
                                        <img className="img-2" src="assets/img/about/05.jpg" alt />
                                        <div className="about-img-shape">
                                            <img src="assets/img/shape/06.png" alt />
                                        </div>
                                    </div>
                                    <div className="about-experience">
                                        <span>17</span>
                                        <h5>Years Of <br /> Experience</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-right wow fadeInUp" data-wow-delay=".25s">
                                    <div className="site-heading mb-3">
                                        <span className="site-title-tagline"><i className="fas fa-percent" /> About Us</span>
                                        <h2 className="site-title" style={{color:"#33b6ff"}}>
                                            Company Profile
                                        </h2>
                                    </div>
                                    <p className="about-text" style={{textAlign:"center"}}>Bright Jobs India is a leading Recruitment firm specialized in hiring candidates for various industry like Technology, Automobile, Manufacturing, Finance, Production, Marketing, Sales etc. and is active in the market Since Jan 2007. We provide professional and unique executives headhunting services. Our team consists of HR Professionals who collectively have more than 10 years of Recruitment Industry and HR experience.
                                    </p>
                                    {/* <div className="about-list-wrap">
                                        <ul className="about-list">
                                            <li>
                                                <div className="icon">
                                                    <img src="assets/img/icon/rating-2.svg" alt />
                                                </div>
                                                <div className="content">
                                                    <h4>Clients Satisfaction</h4>
                                                    <p>Take a look at our round up of the best shows.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <img src="assets/img/icon/team.svg" alt />
                                                </div>
                                                <div className="content">
                                                    <h4>Professional Team</h4>
                                                    <p>It has survived words which not only five centuries.</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div> */}
                                    {/* <a to="about.html" className="theme-btn">Discover More<i className="fas fa-arrow-right" /></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="counter-area pt-40 pb-40">
                    <div className="counter-shape">
                        <img className="img-1" src="assets/img/shape/04.png" alt />
                        <img className="img-2" src="assets/img/shape/05.png" alt />
                    </div>
                    <div className="container">
                        <div className="row g-4 justify-content-center">
                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <div className="counter-box wow fadeInUp" data-wow-delay=".25s">
                                    <div className="icon">
                                        <img src="assets/img/icon/tax.svg" alt />
                                    </div>
                                    <div className="content">
                                        <div className="info">
                                            <span className="counter" data-count="+" data-to={150} data-speed={3000}>500</span>
                                            <span className="unit">k</span>
                                        </div>
                                        <h6 className="title">Served Clients</h6>
                                    </div>
                                </div>


                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <div className="counter-box wow fadeInDown" data-wow-delay=".25s">
                                    <div className="icon">
                                        <img src="assets/img/icon/rating.svg" alt />
                                    </div>
                                    <div className="content">
                                        <div className="info">
                                            <span className="counter" data-count="+" data-to={25} data-speed={3000}>25</span>
                                            <span className="unit">K</span>
                                        </div>
                                        <h6 className="title">Happy Clients</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <div className="counter-box wow fadeInUp" data-wow-delay=".25s">
                                    <div className="icon">
                                        <img src="assets/img/icon/staff.svg" alt />
                                    </div>
                                    <div className="content">
                                        <div className="info">
                                            <span className="counter" data-count="+" data-to={120} data-speed={3000}>6,000</span>
                                            <span className="unit">+</span>
                                        </div>
                                        <h6 className="title">Placed Candidates</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <div className="counter-box wow fadeInDown" data-wow-delay=".25s">
                                    <div className="icon">
                                        <img src="assets/img/icon/award.svg" alt />
                                    </div>
                                    <div className="content">
                                        <div className="info">
                                            <span className="counter" data-count="+" data-to={50} data-speed={3000}>50</span>
                                            <span className="unit">+</span>
                                        </div>
                                        <h6 className="title">Win Awards</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonials />
            <Client />

        </>
    )
}

export default About