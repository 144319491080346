import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderServices() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <div className="case-item">
                        <div className="case-img">
                            <Link className="popup-img case-link" to="/Service"> <i className="fal fa-plus" /></Link>
                            <img className="img-fluid" src="assets/img/case/01.jpg" alt />
                            <div className="case-btn">
                                <Link  style={{color:"white"}} to="/Service"><i className="far fa-arrow-right" /></Link>
                            </div>
                        </div>
                        <div className="case-content">
                            <small>Learn More</small>
                            <h4><Link  to="/Service">Permanent Staffing </Link></h4>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="case-item">
                <div className="case-img">
                  <Link className="popup-img case-link" to="/Service"> <i className="fal fa-plus" /></Link>
                  <img className="img-fluid" src="assets/img/case/02.jpg" alt />
                  <div className="case-btn">
                    <Link to="/Service"><i className="far fa-arrow-right" /></Link>
                  </div>
                </div>
                <div className="case-content">
                  <small>Learn More</small>
                  <h4><Link to="/Service">Turnkey Recruitment Assignments </Link></h4>
                </div>
              </div>
                </SwiperSlide>
                <SwiperSlide>
                <div className="case-item">
                <div className="case-img">
                  <Link className="popup-img case-link" to="/Service"> <i className="fal fa-plus" /></Link>
                  <img className="img-fluid" src="assets/img/case/03.jpg" alt />
                  <div className="case-btn">
                    <Link to="/Service"><i className="far fa-arrow-right" /></Link>
                  </div>
                </div>
                <div className="case-content">
                  <small>Learn More</small>
                  <h4><Link to="/Service">Executive Headhunting</Link></h4>
                </div>
              </div>
                </SwiperSlide>



            </Swiper>
        </>
    );
}
