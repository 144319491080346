import React from 'react'
import { Link } from "react-router-dom";


const HomeAbout = () => {
    return (
        <>
            <div>
                <div className="about-area py-120">
                {/* <div className="about-area py-120"> */}
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-left wow fadeInLeft" data-wow-delay=".25s">
                                    <div className="about-img">
                                        <img className="img-1" src="assets/img/about/01.jpg" alt />
                                        <img className="img-2" src="assets/img/about/05.jpg" alt />
                                        <div className="about-img-shape">
                                            <img src="assets/img/shape/06.png" alt />
                                        </div>
                                    </div>
                                    <div className="about-experience">
                                        <span>17</span>
                                        <h5>Years Of <br /> Experience</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-right wow fadeInUp" data-wow-delay=".25s">
                                    <div className="site-heading mb-3">
                                        <span className="site-title-tagline"><i className="fas fa-percent" /> About Us</span>
                                        <h2 className="site-title" style={{color:"#33b6ff"}}>
                                            Company Profile
                                        </h2>
                                    </div>
                                    <div className="about-list-wrap">
                                        <ul className="about-list">
                                            <li>
                                                <div className="icon">
                                                    <img src="assets/img/testimonial/Profile.png" alt />
                                                </div>
                                                <div className="content">
                                                    <h4>Santosh Mahadikar</h4>
                                                    <p>Managing Director</p>
                                                </div>
                                            </li>
                                            {/* <li>
                                                <div className="icon">
                                                    <img src="assets/img/icon/team.svg" alt />
                                                </div>
                                                <div className="content">
                                                    <h4>Professional Team</h4>
                                                    <p>It has survived words which not only five centuries.</p>
                                                </div>
                                            </li> */}
                                        </ul>
                                    </div>
                                    <p className="about-text" style={{ textAlign: "justify", marginRight:"18px" }}>Bright Jobs India is a leading Recruitment firm specialized in hiring candidates for various industry like Technology, Automobile, Manufacturing, Finance, Production, Marketing, Sales etc. and is active in the market Since Jan 2007. We provide professional and unique executives headhunting services. Our team consists of HR Professionals who collectively have more than 10 years of Recruitment Industry and HR experience.
                                    </p>
                                    
                                    <Link to="/about" className="theme-btn mt-lg-5"> About More<i className="fas fa-arrow-right" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="counter-area pt-40 pb-40 mt-lg-5">
                    <div className="counter-shape">
                        <img className="img-1" src="assets/img/shape/04.png" alt />
                        <img className="img-2" src="assets/img/shape/05.png" alt />
                    </div>
                    <div className="container">
                        <div className="row g-4 justify-content-center">
                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <div className="counter-box wow fadeInUp" data-wow-delay=".25s">
                                    <div className="icon">
                                        <img src="assets/img/icon/tax.svg" alt />
                                    </div>
                                    <div className="content">
                                        <div className="info">
                                            <span className="counter" data-count="+" data-to={150} data-speed={3000}>500</span>
                                            <span className="unit" style={{color:"white"}}>k</span>
                                        </div>
                                        <h6 className="title">Served Clients</h6>
                                    </div>
                                </div>


                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <div className="counter-box wow fadeInDown" data-wow-delay=".25s">
                                    <div className="icon">
                                        <img src="assets/img/icon/rating.svg" alt />
                                    </div>
                                    <div className="content">
                                        <div className="info">
                                            <span className="counter" data-count="+" data-to={25} data-speed={3000}>25</span>
                                            <span className="unit" style={{color:"white"}}>K</span>
                                        </div>
                                        <h6 className="title">Happy Clients</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <div className="counter-box wow fadeInUp" data-wow-delay=".25s">
                                    <div className="icon">
                                        <img src="assets/img/icon/staff.svg" alt />
                                    </div>
                                    <div className="content">
                                        <div className="info">
                                            <span className="counter" data-count="+" data-to={120} data-speed={3000}>6,000</span>
                                            <span className="unit" style={{color:"white"}}>+</span>
                                        </div>
                                        <h6 className="title">Placed Candidates</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-3">
                                <div className="counter-box wow fadeInDown" data-wow-delay=".25s">
                                    <div className="icon">
                                        <img src="assets/img/icon/award.svg" alt />
                                    </div>
                                    <div className="content">
                                        <div className="info">
                                            <span className="counter" data-count="+" data-to={50} data-speed={3000}>50</span>
                                            <span className="unit" style={{color:"white"}}>+</span>
                                        </div>
                                        <h6 className="title">Win Awards</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeAbout