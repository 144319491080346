import React from 'react'

const VisionMissionValues = () => {
    return (
        <>
            <div className="feature-area py-100 mt-lg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="site-heading text-center wow fadeInDown" data-wow-delay=".25s">
                                <span className="site-title-tagline"><i className="fas fa-percent" /> Our Mission</span>
                                <h2 className="site-title" style={{color:"#33b6ff"}}> Mission <span>Vision</span></h2>
                               
                            </div>
                        </div>
                    </div>
                    <p>At Bright Jobs, we work hard to achieve our goals together as a team with a clear shared purpose. We adapt to our clients changing needs as well changes in the market to make sure we are a business of growth, success and happiness.Our vision, mission and values help us to do this.
                    </p>
                    <div className="feature-wrap mt-40 mt-lg-5">
                        <div className="row g-4">
                            <div className="col-md-6 col-lg-4">
                                <div className="feature-item wow fadeInUp" data-wow-delay=".25s">
                                    <span className="count">01</span>
                                    <div className="feature-icon">
                                        <img src="assets/img/icon/Mission.png" alt />
                                    </div>
                                    <div className="feature-content">
                                        <h4>Mission</h4>
                                        <p>Together we communicate on a personal level listening to the individual need and providing a choice of solution.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="feature-item wow fadeInDown" data-wow-delay=".25s">
                                    <span className="count">02</span>
                                    <div className="feature-icon">
                                        <img src="assets/img/icon/Visi.png" alt />
                                    </div>
                                    <div className="feature-content">
                                        <h4>Vision</h4>
                                        <p>To change the perception of recruitment by devoting ourselves to the happiness of our clients, candidates and staff.
                                        .</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="feature-item wow fadeInUp" data-wow-delay=".25s">
                                    <span className="count">03</span>
                                    <div className="feature-icon">
                                        <img src="assets/img/icon/team-2.svg" alt />
                                    </div>
                                    <div className="feature-content">
                                        <h4>Values</h4>
                                        <p>✓ Excellence 
                                            ✓ Integrity 
                                            <br/> ✓ Passion 
                                            ✓ Respect 
                                            <br/> ✓ Clarity 
                                            ✓ Accountability 
                                            </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-6 col-lg-3">
                                <div className="feature-item wow fadeInDown" data-wow-delay=".25s">
                                    <span className="count">04</span>
                                    <div className="feature-icon">
                                        <img src="assets/img/icon/support.svg" alt />
                                    </div>
                                    <div className="feature-content">
                                        <h4>24/7 Support</h4>
                                        <p>It is a long established fact that a reader will be distracted by the readable
                                            content of the page looking layout point.</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default VisionMissionValues