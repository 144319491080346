import React from 'react'
import HomeBanner from './home/HomeBanner'
import WhyChooseUs from './home/WhyChooseUs'
import Testimonials from './home/Testimonials'
import Client from './home/Client'
import HomeAbout from './home/HomeAbout'
import VisionMissionValues from './home/VisionMissionValues'
import HomeServices from './home/HomeServices'

const Home = () => {
  return (
    <>
      <HomeBanner />
      <HomeAbout />
      <VisionMissionValues />
      <HomeServices />
      <WhyChooseUs />
      <Testimonials />
      <Client />
    </>
  )
}

export default Home