import React from 'react'

const WhyChooseUs = () => {
    return (
        <>
            <div className="choose-area py-100 mt-lg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="choose-content wow fadeInUp" data-wow-delay=".25s">
                                <div className="site-heading mb-0">
                                    <span className="site-title-tagline"><i className="fas fa-percent" /> Why Choose Us</span>
                                    <h2 className="site-title" style={{color:"#33b6ff"}}>Why Choose Bright  <span>Jobs India</span></h2>
                                    <p>
                                    Bright Jobs India offers unparalleled recruitment services across various industries. Our team of experienced HR professionals ensures that we find the best candidates who align with your 
                                    company’s values and goals.
                                    </p>
                                </div>
                                <div className="choose-content-wrap">
                                    <div className="choose-item">
                                        <div className="choose-item-icon">
                                            <img src="assets/img/icon/money.svg" alt />
                                        </div>
                                        <div className="choose-item-info">
                                            <h4>Access to a wider talent pool.</h4>
                                            
                                        </div>
                                    </div>
                                    <div className="choose-item">
                                        <div className="choose-item-icon">
                                            <img src="assets/img/icon/investment.svg" alt />
                                        </div>
                                        <div className="choose-item-info">
                                            <h4>Confidentiality in the recruitment process.</h4>
                                          
                                        </div>
                                    </div>
                                    <div className="choose-item">
                                        <div className="choose-item-icon">
                                            <img src="assets/img/icon/money-2.svg" alt />
                                        </div>
                                        <div className="choose-item-info">
                                            <h4>Tailored search strategies to meet specific needs.</h4>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="choose-img wow fadeInRight" data-wow-delay=".25s">
                                <img className="img-1" src="assets/img/choose/01.jpg" alt />
                                <img className="img-2" src="assets/img/choose/02.jpg" alt />
                                <img className="img-shape" src="assets/img/shape/10.png" alt />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default WhyChooseUs