import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import Service from "./pages/services/Service";
import TermsConditions from "./pages/termsconditions/TermsConditions";
import Blog from "./pages/blog/Blog";
import Career from "./pages/career/Career";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/career" element={<Career />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/contact" element={<Contact />} />
           
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
