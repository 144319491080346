import React from 'react'
import { Link } from "react-router-dom";


const TermsConditions = () => {
    return (
        <>

            <div>
                <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/01.jpg)' }}>
                    <div className="container">
                        <h2 className="breadcrumb-title">Terms & Conditions</h2>
                        <ul className="breadcrumb-menu">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">Terms & Conditions</li>
                        </ul>
                    </div>
                </div>
                <div className="py-120">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="terms-content">
                                    <h3>Service Charges</h3>
                                    <p>
                                    ❑	Entry & Mid Level Hiring  - 8.33% + GST
                                    Sr Executive-Level Hiring - 10% -12 % + GST
                                    {/* ❑	For Junior, Middle and Senior positions we charge 8.33% on CTC (Cost to Company). */}
                                    </p>
                                    <p>
                                    ❑	GST: Will be charged @ 18 % on the total bill as per the Govt. notification
                                    </p>
                                </div>
                                <div className="terms-content">
                                    <h3>TRAVEL EXPENSES </h3>
                                    <p>
                                    Travel expenses incurred by the outstation candidates called for interviews by the client must be reimbursed directly to the candidate as per existing market practices.
                                    </p>
                                </div>
                                <div className="terms-content">
                                    <h3>CORRESPONDENCE WITH CANDIDATES </h3>
                                    <p>
                                    Copies of all communications to candidates including interview call letters, appointment letters, etc. should be marked to us for follow-up. Full particulars of offers made to candidates should be conveyed to us, to ensure commitment of the candidates to take up employment.
                                    </p>
                                </div>
                                <div className="terms-content">
                                    <h3>PLACEMENT CREDIT </h3>
                                    <p>
                                    Candidates referred by us and selected by the client will be deemed to be our placement. This would also apply if the candidate is selected for a position other than that for which he/ she has been referred. This would be valid for a period of ONE year from the date of submission of the resumes by us to the Client.
                                    </p>
                                  
                                </div>
                                <div className="terms-content">
                                    <h3>SERVICE GUARANTEE :</h3>
                                    <p>We provide 90 days service guarantee. If the candidate quits the job or the client is not satisfied with the performance of the candidate and decides to terminate the candidate, we shall assist to replace another candidate as complimentary service to our clients.</p>
                                   
                                </div>
                                <div className="terms-content">
                                    <h3>PAYMENTS</h3>
                                    <p>
                                    All bills should be settled within 30 Days from the date of joining of the candidate by A/c payee cheque in favor of Bright Jobs India.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default TermsConditions