import React from 'react'
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <>

      <footer className="footer-area">
        <div className="footer-shape">
          <img src="assets/img/shape/03.png" alt />
        </div>
        <div className="footer-widget">
          <div className="container">
            <div className="footer-widget-wrap pb-70">
            {/* <div className="footer-widget-wrap pt-100 pb-70"> */}
              <div className="row g-5">
                <div className="col-md-6 col-lg-5">
                  <div className="footer-widget-box about-us">
                    <a to="#" className="footer-logo">
                      <img src="/assets/img/job/Untitled design (14).png" alt />
                      {/* <img src="/assets/img/job/Untitled design (14).png" alt /> */}
                    </a>
                    <p className="mb-4">
                    Bright Jobs India is a leading Recruitment firm specialized in hiring candidates for various industry like Technology, Automobile, Manufacturing, Finance, Production, Marketing, Sales etc. and is active in the market Since Jan 2007. 
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="footer-widget-box list">
                    <h4 className="footer-widget-title">Company</h4>
                    <ul className="footer-list" style={{ color: "white" }}>
                      <li><Link to="/"><i className="far fa-arrow-right" />Home</Link></li>
                      <li style={{ color: "white" }}><Link to="/about"><i className="far fa-arrow-right" />About Us</Link></li>
                      <li><Link to="/Service"><i className="far fa-arrow-right" />Services</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-2">
                  <div className="footer-widget-box list">
                    <h4 className="footer-widget-title">Services</h4>
                    <ul className="footer-list" style={{ color: "white" }}>
                      <li><Link to="/blog"><i className="far fa-arrow-right" />Blog</Link></li>
                      <li><Link to="/contact"><i className="far fa-arrow-right" />Contact Us</Link>
                      </li>
                      <li><Link to="/terms-conditions"><i className="far fa-arrow-right" />Terms and Conditions</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="footer-widget-box">
                    <h4 className="footer-widget-title">Get In Touch</h4>
                    <ul className="footer-contact">
                      <li>
                        <div className="icon">
                          <i className="far fa-location-dot" />
                        </div>
                        <div className="content">
                          <h6>Our Address</h6>
                          <p>2nd Floor, NTC Building. M.G. Road, Chikmagalur-577101</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="far fa-phone" />
                        </div>
                        <div className="content">
                          <h6>Call Us</h6>
                          <a href="tel:+916366288356">+91 6366288356
                            <br/>+91 9739095534
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="far fa-envelope" />
                        </div>
                        <div className="content">
                          <h6>contact@brightjobsindia.com</h6>
                          <a href="mailto:contact@brightjobsindia.com"><span className="__cf_email__"></span></a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <p className="copyright-text">
                  © 2024 Copyright <span id="date" /> <a to="#"> Bright Jobs India </a> | Designed by <a href='https://www.dexterous.in/' target="_blank">Dexterous Technology.</a>
                </p>
              </div>
              <div className="col-md-6 align-self-center">
                <ul className="footer-social">
                  <li><a to="#"><i className="fab fa-facebook-f" /></a></li>
                  <li><a to="#"><i className="fab fa-x-twitter" /></a></li>
                  <li><a to="#"><i className="fab fa-linkedin-in" /></a></li>
                  <li><a to="#"><i className="fab fa-youtube" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer