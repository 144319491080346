import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function OurFeaturedProjectsSlider() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 2,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 2,
                        spaceBetween: 50,
                    },
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <div className="testimonial-item">
                        <div className="testimonial-quote">
                            <span className="testimonial-quote-icon"><i className="fal fa-quote-right" /></span>
                            <p>
                            Bright Jobs India truly understands the market. They delivered candidates who fit our culture perfectly.
                            </p>
                        </div>
                        <div className="testimonial-content">
                            <div className="testimonial-author-img">
                                <img src="assets/img/testimonial/Profile.png" alt />
                            </div>
                            <div className="testimonial-author-info">
                                <h4>Priya Desai</h4>
                                <p>Marketing Director, Creative Agency</p>
                                <div className="testimonial-rate">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="testimonial-item">
                        <div className="testimonial-quote">
                            <span className="testimonial-quote-icon"><i className="fal fa-quote-right" /></span>
                            <p>
                            Their team provided us with top-notch candidates quickly. We are very satisfied with the results.
                            </p>
                        </div>
                        <div className="testimonial-content">
                            <div className="testimonial-author-img">
                                <img src="assets/img/testimonial/Profile.png" alt />
                            </div>
                            <div className="testimonial-author-info">
                                <h4>Vikram Singh</h4>
                                <p>Operations Head, Manufacturing Corp </p>
                                <div className="testimonial-rate">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="testimonial-item">
                        <div className="testimonial-quote">
                            <span className="testimonial-quote-icon"><i className="fal fa-quote-right" /></span>
                            <p>
                            The recruitment process was seamless and efficient. I highly recommend their services for any hiring needs.
                            </p>
                        </div>
                        <div className="testimonial-content">
                            <div className="testimonial-author-img">
                                <img src="assets/img/testimonial/Profile.png" alt />
                            </div>
                            <div className="testimonial-author-info">
                                <h4>Anita Sharma</h4>
                                <p>HR Manager, Auto Solutions</p>
                                <div className="testimonial-rate">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="testimonial-item">
                        <div className="testimonial-quote">
                            <span className="testimonial-quote-icon"><i className="fal fa-quote-right" /></span>
                            <p>
                            Bright Jobs India helped us find the perfect candidates for our tech team. Their understanding of our needs was exceptional.
                            </p>
                        </div>
                        <div className="testimonial-content">
                            <div className="testimonial-author-img">
                                <img src="assets/img/testimonial/Profile.png" alt />
                            </div>
                            <div className="testimonial-author-info">
                                <h4>Rajesh Kumar</h4>
                                <p>CTO, Tech Innovations</p>
                                <div className="testimonial-rate">
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                    <i className="fas fa-star" />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>


            </Swiper>
        </>
    );
}
