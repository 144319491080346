import React, { useState } from 'react'
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";


const Career = () => {


    const [name, setName] = useState("");
    const [mail, setmail] = useState("");
    const [massages, setMassages] = useState("");
    const [number, setNumber] = useState("");
    const [resume, setResume] = useState("");

    const nameChange = (e) => {
        const value = e.target.value;
        setName(value);
    };
    const resumeChange = (e) => {
        const value = e.target.value;
        setResume(value);
    };

    const mailChange = (e) => {
        const value = e.target.value;
        setmail(value);
    };
    const massagesChange = (e) => {
        const value = e.target.value;
        setMassages(value);
    };
    const numberChange = (e) => {
        const value = e.target.value;
        setNumber(value);
    };


    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(

                "service_pxk7za8",
                "template_5dxfa8v",
                "#myForm",
                "cmKsjKcLM6DrY65C2"
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    if (response.text === "OK") {
                        setName("");
                        setmail("");
                        setMassages("");
                        setNumber("");
                        setResume("");

                    }
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
    };
    return (
        <>
            <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/01.jpg)' }}>
                <div className="container">
                    <h2 className="breadcrumb-title">Career</h2>
                    <ul className="breadcrumb-menu">
                        <li><Link to="/">Home</Link></li>
                        <li className="active">Career</li>
                    </ul>
                </div>
            </div>

            <div className="quote-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 ms-auto">
                            <div className="quote-content">
                                <div className="quote-head">
                                    <h3>Get Your Quote</h3>
                                    {/* <p>It is a long established fact that a reader will be distracted by the
                                        readable content of majority have suffered alteration page when looking at its
                                        layout.</p> */}
                                </div>
                                <div className="quote-form">
                                    <form action="#" onSubmit={sendEmail} id="myForm">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="far fa-user-tie" /></span>
                                                    <input type="text" required className="form-control" placeholder="Your Name"
                                                        name="from_name"
                                                        onChange={(e) => nameChange(e)}
                                                        value={name}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="far fa-envelope" /></span>
                                                    <input type="email" required className="form-control" placeholder="Your Email"
                                                        name='email'
                                                        onChange={(e) => mailChange(e)}
                                                        value={mail}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="far fa-phone" /></span>
                                                    <input type="text" required className="form-control" placeholder="Your Phone"
                                                        name="number"
                                                        onChange={(e) => numberChange(e)}
                                                        value={number}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6">
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="far fa-phone" /></span>
                                                    <input type="text" required className="form-control" placeholder="resume"
                                                         name='resume'
                                                         onChange={(e) => resumeChange(e)}
                                                         value={resume}
                                                    />
                                                </div>
                                            </div> */}
                                            {/* <div className="col-lg-6">
                                                <div className="input-group">
                                                    <span className="input-group-text"><i className="far fa-box" /></span>
                                                    <input type="file" class="form-control"
                                                        name='resume'
                                                        onChange={(e) => resumeChange(e)}
                                                        value={resume}
                                                    ></input>
                                                </div>
                                            </div> */}
                                            <div className="col-lg-6">
                                                <div className="input-group textarea">
                                                    <span className="input-group-text"><i className="far fa-comment-lines" /></span>
                                                    <textarea className="form-control" placeholder="Your Message"
                                                    // <textarea className="form-control" cols={30} rows={4} placeholder="Your Message"
                                                        onChange={(e) => massagesChange(e)}
                                                        name='massage'
                                                        value={massages}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <a className="btn theme-orange border-0 mt-4 theme-btn">

                                                    <input
                                                        type="submit"
                                                        name="submit"
                                                        defaultValue="Submit Now"
                                                    />
                                                    <i className="fas fa-arrow-right" />
                                                </a>
                                            </div>
                                            {/* <div className="col-lg-12">
                                                <button type="submit" className="theme-btn">Submit Now<i className="fas fa-arrow-right" /></button>
                                            </div> */}
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Career