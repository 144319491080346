import React, { useState } from 'react'
import { Link } from "react-router-dom";


const Hadear = () => {
  const [toggle, setToggle] = useState(false);

  const CloseClick = () => {
    setToggle(false);

  };
  const [ismobile, setIsmobile] = useState(0);

  // const { categorytotal } = useSelector((store) => store.categoryAll);


  const [showAbout, setShowAbout] = useState(true);
  const [showInterior, setShowInterior] = useState(true);
  const [showGallery, setShowGallery] = useState(true);

  const closemenu = () => {
    document.getElementById("mytogglebtn").classList.add("collapsed");
    document.getElementById("menu").classList.remove("in");
  };
  return (
    <>

      <header className="header">
        {/* <div className="header-top">
          <div className="container">
            <div className="header-top-wrap">
              <div className="header-top-left">
                <div className="header-top-contact">
                  <ul style={{color:"white"}}>
                    <li><Link href="mailto:contact@brightjobsindia.com"><i className="far fa-envelopes" />
                      <span className="__cf_email__" data-cfemail="650c0b030a25001d04081509004b060a08"> contact@brightjobsindia.com</span></Link>
                    </li>
                    <li><Link href="tel:+919739095534"><i className="far fa-phone-volume" /> +91 9739095534
                   </Link>
                    </li>
                    <li><Link to="#"><i className="far fa-alarm-clock"  /> Sun - Fri (09AM - 06PM)</Link></li>
                  </ul>
                </div>
              </div>
              <div className="header-top-right">
                <div className="header-top-social">
                  <span>Follow Us: </span>
                  <Link to="#"><i className="fab fa-facebook" /></Link>
                  <Link to="#"><i className="fab fa-x-twitter" /></Link>
                  <Link to="#"><i className="fab fa-instagram" /></Link>
                  <Link to="#"><i className="fab fa-linkedin" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="main-navigation">
          <nav className="navbar navbar-expand-lg">
            <div className="container position-relative">
              <Link className="navbar-brand" to="/">
                <img src="/assets/img/job/bright_Jobs_India_logo.png" alt="logo" />
              </Link>
              <div className="mobile-menu-right">
                {/* <div className="search-btn">
                  <button type="button" className="nav-right-link search-box-outer"><i className="far fa-search" /></button>
                </div> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-mobile-icon"><i className="far fa-bars" /></span>
                </button>
              </div>
              <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav" style={{marginRight:"11px", marginLeft:"50px"}}>
              
                  <li className="nav-item"><Link className="nav-link" to="/" onClick={closemenu}>Home</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="/about" onClick={closemenu}>About Us</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="/career" onClick={closemenu}>Career</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="/Service" onClick={closemenu}>Services</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="/terms-conditions" onClick={closemenu}>Terms & Conditions</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="/blog" onClick={closemenu}>Blog</Link></li>
                  <li className="nav-item"><Link className="nav-link" to="/contact" onClick={closemenu}>Contact Us</Link></li>
                </ul>
                {/* <div className="nav-right">
                  <div className="nav-right-btn">
                    <Link to="/contact" className="theme-btn">Let's Talk<i className="fas fa-arrow-right" /></Link>
                  </div>
                 
                </div> */}
              </div>
            </div>
          </nav>
        </div>
      </header>



    </>
  )
}

export default Hadear