import React from 'react'
import { Link } from "react-router-dom";


const Service = () => {
    return (
        <>

            <div>
                <div className="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/01.jpg)' }}>
                    <div className="container">
                        <h2 className="breadcrumb-title">Our Services</h2>
                        <ul className="breadcrumb-menu">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">Our Services</li>
                        </ul>
                    </div>
                </div>
                <div className="service-area2 bg py-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                                <div className="site-heading text-center wow fadeInDown" data-wow-delay=".25s">
                                    <span className="site-title-tagline light"><i className="fas fa-percent" /> Our Services</span>
                                    <h2 className="site-title" style={{color:"#33b6ff"}}>Our Professional  <span>Recruitment Services</span></h2>
                                </div>
                            </div>
                        </div>
                        <p>Bright Jobs India offers a range of specialized recruitment services to meet the diverse needs of our clients. Our team of experienced HR professionals is dedicated to finding the best talent for your organization.</p>
                        <div className="row g-4">
                            <div className="col-md-6 col-lg-6">
                                <div className="service-item wow fadeInUp" data-wow-delay=".25s">
                                   
                                    <div className="service-img">
                                        <img src="assets/img/service/04.jpg" alt />
                                    </div>
                                    <div className="service-content">
                                        <div className="service-icon">
                                            <img src="assets/img/icon/tax-4.svg" alt />
                                        </div>
                                        <div className="service-info">
                                            <h3 className="service-title">
                                                <a to="service-single.html">Permanent Staffing </a>
                                            </h3>
                                            <p className="service-text">
                                            Caters to requirements for Junior, Middle and Management Level positions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="service-item wow fadeInUp" data-wow-delay=".25s">
                                   
                                    <div className="service-img">
                                        <img src="assets/img/service/05.jpg" alt />
                                    </div>
                                    <div className="service-content">
                                        <div className="service-icon">
                                            <img src="assets/img/icon/audit.svg" alt />
                                        </div>
                                        <div className="service-info">
                                            <h3 className="service-title">
                                                <a to="service-single.html">Turnkey Recruitment Assignments </a>
                                            </h3>
                                            <p className="service-text">
                                            Specialized recruitment process that handles large volume requirements; best suited for start-ups/new projects including large scale expansion of team across geographical locations.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Service